import React from "react";
import Layout from "../components/layout";
import "../styles/404.css";
import { Link } from "gatsby";
import MetaData from "../components/common/MetaData";
const NotFound = () => {
  return (
    <Layout>
      <MetaData pageTitle="Page not found | Grootan Technologies" />
      <div className="header integrations-header section h-auto main-container">
        <div className="text-container">
          <div className="primary-text" >
            Oops!
            <div className="text-big">Seems like you got lost somewhere.</div>
          </div>
        </div>
        <div className="btn-container pb-5">
          <Link className={"btn btn-primary btn-lg btn-rounded bold px-4 py-2 w-auto"} to="/">Back To Home</Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
